<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Roles" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div>
          <md-button class="md-primary" :to="{ name: 'Create Role' }">
            Add New Role
          </md-button>
          <md-button class="md-primary" :to="{ name: 'Permission Management' }">
            Permissions
          </md-button>
        </div>
      </div>
      <md-table
        v-model="roles.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4"> </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S/N">{{ index + 1 }}</md-table-cell>
          <md-table-cell md-label="Name">{{ item.display_name }}</md-table-cell>
          <md-table-cell md-label="Slug">{{ item.name }}</md-table-cell>
          <md-table-cell md-label="Guard Name">{{
            item.guard_name
          }}</md-table-cell>
          <md-table-cell md-label="Actions">
            <md-button
              :disabled="updating"
              :to="{ name: 'Edit Role', params: { id: item.id } }"
              class="md-default md-icon-button md-raised btn-icon"
              ><i class="material-icons">edit</i></md-button
            >
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="roles.mdPage"
        :records="roles.mdCount"
        :per-page="roles.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Edit Role</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="user">
        <div class="item">
          <strong>First name</strong>
          <span> {{ user.first_name }}</span>
        </div>
        <div class="item">
          <strong>Last name</strong>
          <span> {{ user.last_name }}</span>
        </div>
        <div class="item">
          <strong>Email Address</strong>
          <span v-html="user.email"> </span>
        </div>
        <div class="item">
          <strong>Phone Number</strong>
          <a :href="`tel:${user.phone_ext + user.phone}`">
            {{ user.phone_ext + user.phone }}</a
          >
        </div>

        <div class="item">
          <strong>Date Created</strong>
          <span> {{ user.created_at | formatDate }}</span>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import axios from "axios";
const { mapActions, mapGetters } = createNamespacedHelpers("user");
export default {
  name: "User",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      show: false,
      time: null,
      user: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getRoles", "loginAsUser"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getRoles(data);
    },
    async submitSearch() {
      await this.getRoles({ keyword: this.search });
    },

    setUser(user) {
      this.show = true;
      this.user = user;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async deleteUser(id) {
      const c = confirm(`Are you sure? This can't be reversed`);
      if (c) {
        alert();
      }
    },
  },
  mounted() {
    this.getRoles();
  },
  computed: {
    ...mapGetters(["roles", "loading"]),
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}
</style>
